<template>
  <section class="section hero">
    <div class="container">
      <h3 class="hero__subtitle">Вітаємо Вас на сторінці голосування</h3>
      <h1 class="large-title hero__title">Charity Queen of Ukraine 2024</h1>
      <div class="hero__txt">
        <p>
          «Charity Queen of Ukraine» - це всеукраїнський конкурс краси,
          створений Асоціацією Королев, як потужна платформа для самореалізації
          та благодійної ініціативи.
        </p>
      </div>
      <div class="hero__txt">
        <p>
          Метою конкурса є популяризації української жіночої вроди, як в
          Україні, так і за її межами, адже наші переможці є фіналістками
          світових конкурсів краси.
        </p>
      </div>
    </div>
  </section>
</template>
