<template>
  <footer class="footer flex ai-center flex-column">
    <div class="footer__logo">
      <svg
        width="186"
        height="50"
        viewBox="0 0 186 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0.193405 38.8793V33.0771L6.96258 38.8793H0.193405Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M66.7247 38.8793V33.0771L59.7621 38.8793H66.7247Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M33.4591 32.4968L26.6899 38.299H40.2282L33.4591 32.4968Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0 1.06856V7.9151C0 8.05048 0.0580215 8.2052 0.174064 8.30191L2.86239 10.7001C3.09448 10.9129 3.09448 11.261 2.86239 11.4737L0.174064 13.7366C0.0580215 13.8333 0 13.988 0 14.1234V27.2749C0 27.4103 0.0580215 27.5457 0.154724 27.6424L11.2948 38.1443C11.3916 38.241 11.5269 38.2797 11.643 38.2797H22.1449C22.2802 38.2797 22.4156 38.2216 22.5123 38.1249L33.1303 27.6231C33.227 27.5264 33.3623 27.4683 33.4977 27.4683H33.9039C34.0393 27.4683 34.1746 27.5264 34.2907 27.6231L44.193 38.0863C44.2897 38.183 44.4251 38.241 44.5798 38.241H55.1978C55.3331 38.241 55.4685 38.183 55.5652 38.1056L66.57 27.6037C66.6667 27.507 66.7247 27.3716 66.7247 27.2362V14.0847C66.7247 13.9687 66.686 13.8333 66.5893 13.7366L64.5005 11.4157C64.3265 11.2223 64.3265 10.9322 64.5005 10.7388L66.6087 8.28257C66.686 8.18586 66.7247 8.06982 66.7247 7.95378V1.02988C66.7247 0.565709 66.1638 0.352964 65.835 0.662412L55.7586 10.7195C55.5652 10.9129 55.5652 11.2417 55.7586 11.4351L62.992 18.6877C63.1854 18.8812 63.1854 19.2099 62.992 19.4227L49.8405 31.994C49.6277 32.1874 49.2989 32.1874 49.1055 31.9747L37.1337 19.2099C37.037 19.1132 36.9984 18.9972 36.9984 18.8618V18.3203C36.9984 18.1849 37.0564 18.0495 37.1531 17.9528L43.9996 11.4351C44.2124 11.2417 44.2124 10.9129 44.019 10.7195L33.6718 0.159559C33.459 -0.0531863 33.1303 -0.0531863 32.9175 0.159559L22.7251 10.7001C22.5317 10.9129 22.5317 11.2417 22.7638 11.4351L30.2679 17.9528C30.3839 18.0495 30.4419 18.1849 30.4419 18.3396V18.8618C30.4419 18.9972 30.3839 19.1326 30.2872 19.2293L17.4064 32.2067C17.3097 32.3035 17.1744 32.3615 17.039 32.3615H16.3814C16.246 32.3615 16.1106 32.3035 16.0139 32.2067L3.44261 19.2293C3.3459 19.1326 3.30722 19.0165 3.30722 18.8812V18.3396C3.30722 18.2042 3.36525 18.0689 3.46195 17.9722L10.4245 11.4544C10.6373 11.261 10.6373 10.9322 10.4439 10.7388L0.889663 0.720433C0.580215 0.372304 0 0.60439 0 1.06856Z"
          fill="white"
        />
        <path
          d="M66.7247 45.1648H0.193405V49.9999H66.7247V45.1648Z"
          fill="white"
        />
        <path
          d="M75.7567 17.1405H81.7909L82.9514 19.9255H86.1039L80.3017 6.92871H77.3233L71.5211 19.9255H74.5963L75.7567 17.1405ZM78.7738 9.88781L80.8432 14.8583H76.7237L78.7738 9.88781Z"
          fill="white"
        />
        <path
          d="M88.7535 18.2622C89.3917 18.8618 90.1267 19.3453 90.9777 19.6741C91.848 20.0029 92.7763 20.1576 93.782 20.1576C94.9231 20.1576 95.9482 19.9642 96.8378 19.558C97.7468 19.1712 98.5204 18.591 99.1393 17.8367L97.2053 16.0574C96.7605 16.5796 96.2576 16.9664 95.7161 17.2178C95.1745 17.4693 94.575 17.5853 93.9368 17.5853C93.3372 17.5853 92.7763 17.4886 92.2735 17.2952C91.7706 17.1018 91.3258 16.8117 90.9583 16.4442C90.5908 16.0768 90.3007 15.6319 90.088 15.1291C89.8946 14.6262 89.7979 14.0653 89.7979 13.4464C89.7979 12.8276 89.8946 12.2667 90.088 11.7638C90.3007 11.261 90.5908 10.8161 90.9583 10.4487C91.3258 10.0812 91.7706 9.7911 92.2735 9.59769C92.7763 9.40429 93.3372 9.30758 93.9368 9.30758C94.575 9.30758 95.1745 9.44297 95.7161 9.69439C96.2576 9.94582 96.7605 10.3133 97.2053 10.8355L99.1393 9.05616C98.5204 8.30188 97.7468 7.741 96.8378 7.35419C95.9288 6.96738 94.9231 6.75464 93.8014 6.75464C92.7957 6.75464 91.848 6.9287 90.997 7.25749C90.146 7.58628 89.3917 8.05045 88.7535 8.65001C88.1153 9.24956 87.6318 9.96516 87.2643 10.7775C86.9162 11.5898 86.7421 12.4988 86.7421 13.4658C86.7421 14.4522 86.9162 15.3418 87.2643 16.1541C87.6124 16.9277 88.1153 17.6433 88.7535 18.2622Z"
          fill="white"
        />
        <path
          d="M112.252 8.61124C111.614 8.01169 110.879 7.52817 110.009 7.19939C109.158 6.8706 108.21 6.69653 107.185 6.69653C106.179 6.69653 105.232 6.8706 104.361 7.19939C103.491 7.52817 102.737 8.01169 102.098 8.61124C101.46 9.2108 100.957 9.9264 100.59 10.7387C100.242 11.551 100.068 12.4407 100.068 13.4077C100.068 14.3747 100.242 15.2644 100.59 16.0767C100.957 16.889 101.441 17.6046 102.079 18.2041C102.717 18.8037 103.472 19.2872 104.342 19.616C105.212 19.9448 106.179 20.1188 107.204 20.1188C108.21 20.1188 109.158 19.9448 110.009 19.616C110.879 19.2872 111.633 18.823 112.252 18.2235C112.89 17.6239 113.393 16.9083 113.761 16.096C114.128 15.2644 114.302 14.3747 114.302 13.4077C114.302 12.4213 114.128 11.5317 113.761 10.7387C113.393 9.94574 112.89 9.23014 112.252 8.61124ZM110.937 15.1096C110.744 15.6125 110.454 16.0573 110.086 16.4248C109.719 16.7923 109.274 17.0824 108.79 17.2758C108.307 17.4692 107.785 17.5659 107.185 17.5659C106.585 17.5659 106.063 17.4692 105.56 17.2758C105.058 17.0824 104.632 16.7923 104.265 16.4248C103.897 16.0573 103.626 15.6125 103.414 15.1096C103.22 14.5875 103.123 14.0266 103.123 13.427C103.123 12.8081 103.22 12.2473 103.414 11.7444C103.626 11.2415 103.916 10.7967 104.284 10.4292C104.651 10.0618 105.077 9.77167 105.56 9.57827C106.063 9.38486 106.585 9.28816 107.185 9.28816C107.785 9.28816 108.307 9.38486 108.81 9.57827C109.312 9.77167 109.738 10.0618 110.086 10.4292C110.454 10.7967 110.744 11.2415 110.937 11.7444C111.15 12.2473 111.246 12.8081 111.246 13.427C111.246 14.0459 111.13 14.6068 110.937 15.1096Z"
          fill="white"
        />
        <path
          d="M127.473 19.9255V22.6719H130.239V17.4693H128.382V6.92871H125.384V17.4693H119.602V6.92871H116.604V19.9255H125.384H127.473Z"
          fill="white"
        />
        <path
          d="M135.209 6.92871H132.212V19.9255H135.209V6.92871Z"
          fill="white"
        />
        <path
          d="M141.205 17.1405H147.239L148.4 19.9255H151.552L145.75 6.92871H142.771L136.969 19.9255H140.044L141.205 17.1405ZM144.222 9.88781L146.291 14.8583H142.153L144.222 9.88781Z"
          fill="white"
        />
        <path
          d="M164.123 19.9255V22.6719H166.889V17.4693H165.032V6.92871H162.054V17.4693H156.252V6.92871H153.273V19.9255H162.054H164.123Z"
          fill="white"
        />
        <path
          d="M171.879 6.92871H168.881V19.9255H171.879V6.92871Z"
          fill="white"
        />
        <path
          d="M179.867 6.92871C178.725 6.92871 177.739 7.12212 176.888 7.50893C176.037 7.89574 175.399 8.45661 174.915 9.17221C174.451 9.88781 174.219 10.7581 174.219 11.7638C174.219 12.7502 174.432 13.5818 174.877 14.2974C175.322 15.013 175.94 15.5352 176.753 15.922C176.83 15.9607 176.907 15.9801 176.985 16.0187L174.277 19.9255H177.449L179.751 16.4636H182.497V19.9255H185.475V6.92871H179.867ZM177.971 9.96517C178.435 9.57836 179.093 9.36561 179.944 9.36561H182.497V14.0847H179.867C179.016 14.0847 178.397 13.8719 177.952 13.4658C177.507 13.0596 177.294 12.4794 177.294 11.7252C177.314 10.9515 177.526 10.3713 177.971 9.96517Z"
          fill="white"
        />
        <path
          d="M84.6727 29.5569H81.5008L78.0969 34.8175H75.9308V29.5569H72.9523V42.5537H75.9308V37.3124H78.0002L81.3848 42.5537H84.808L80.4371 35.8425L84.6727 29.5569Z"
          fill="white"
        />
        <path
          d="M97.4374 31.2397C96.7991 30.6401 96.0642 30.1566 95.1939 29.8278C94.3429 29.499 93.3952 29.325 92.3702 29.325C91.3645 29.325 90.4168 29.499 89.5465 29.8278C88.6761 30.1566 87.9219 30.6401 87.2836 31.2397C86.6454 31.8392 86.1425 32.5548 85.7751 33.3671C85.4269 34.1794 85.2529 35.0691 85.2529 36.0361C85.2529 37.0031 85.4269 37.8928 85.7751 38.7051C86.1425 39.5174 86.6261 40.233 87.2643 40.8325C87.9025 41.4321 88.6568 41.9156 89.5271 42.2444C90.3974 42.5732 91.3645 42.7472 92.3895 42.7472C93.3952 42.7472 94.3429 42.5732 95.1939 42.2444C96.0642 41.9156 96.8185 41.4514 97.4374 40.8519C98.0756 40.2523 98.5785 39.5367 98.9459 38.7244C99.3134 37.8928 99.4875 37.0031 99.4875 36.0361C99.4875 35.0497 99.3134 34.1601 98.9459 33.3671C98.5785 32.5742 98.0756 31.8586 97.4374 31.2397ZM96.1222 37.7381C95.9288 38.2409 95.6387 38.6857 95.2712 39.0532C94.9038 39.4207 94.4589 39.7108 93.9754 39.9042C93.4919 40.0976 92.9697 40.1943 92.3702 40.1943C91.7706 40.1943 91.2484 40.0976 90.7456 39.9042C90.2427 39.7108 89.8172 39.4207 89.4498 39.0532C89.0823 38.6857 88.8115 38.2409 88.5988 37.7381C88.4054 37.2159 88.3087 36.655 88.3087 36.0554C88.3087 35.4365 88.4054 34.8757 88.5988 34.3728C88.8115 33.87 89.1016 33.4251 89.4691 33.0577C89.8366 32.6902 90.2621 32.4001 90.7456 32.2067C91.2484 32.0133 91.7706 31.9166 92.3702 31.9166C92.9697 31.9166 93.4919 32.0133 93.9948 32.2067C94.4976 32.4001 94.9231 32.6902 95.2712 33.0577C95.6387 33.4251 95.9288 33.87 96.1222 34.3728C96.335 34.8757 96.4317 35.4365 96.4317 36.0554C96.4317 36.6743 96.335 37.2352 96.1222 37.7381Z"
          fill="white"
        />
        <path
          d="M110.434 30.1371C109.583 29.7503 108.597 29.5569 107.417 29.5569H101.789V42.5537H104.806V38.9757H107.417C108.578 38.9757 109.583 38.7823 110.434 38.4148C111.285 38.028 111.923 37.4865 112.388 36.7902C112.852 36.094 113.084 35.243 113.084 34.276C113.084 33.2896 112.852 32.458 112.388 31.7424C111.923 31.0461 111.266 30.5046 110.434 30.1371ZM109.332 35.9199C108.868 36.3067 108.171 36.5195 107.243 36.5195H104.787V32.0131H107.243C108.171 32.0131 108.868 32.2065 109.332 32.6127C109.796 32.9995 110.028 33.5604 110.028 34.276C110.009 34.9722 109.796 35.5331 109.332 35.9199Z"
          fill="white"
        />
        <path
          d="M126.506 31.2397C125.868 30.6401 125.133 30.1566 124.263 29.8278C123.412 29.499 122.464 29.325 121.439 29.325C120.433 29.325 119.486 29.499 118.615 29.8278C117.745 30.1566 116.991 30.6401 116.352 31.2397C115.714 31.8392 115.211 32.5548 114.844 33.3671C114.496 34.1794 114.322 35.0691 114.322 36.0361C114.322 37.0031 114.496 37.8928 114.844 38.7051C115.211 39.5174 115.695 40.233 116.333 40.8325C116.971 41.4321 117.726 41.9156 118.596 42.2444C119.466 42.5732 120.433 42.7472 121.458 42.7472C122.464 42.7472 123.412 42.5732 124.263 42.2444C125.133 41.9156 125.887 41.4514 126.506 40.8519C127.144 40.2523 127.647 39.5367 128.015 38.7244C128.382 37.8928 128.556 37.0031 128.556 36.0361C128.556 35.0497 128.382 34.1601 128.015 33.3671C127.647 32.5742 127.144 31.8586 126.506 31.2397ZM125.172 37.7381C124.978 38.2409 124.688 38.6857 124.321 39.0532C123.953 39.4207 123.508 39.7108 123.025 39.9042C122.541 40.0976 122.019 40.1943 121.42 40.1943C120.82 40.1943 120.298 40.0976 119.795 39.9042C119.292 39.7108 118.867 39.4207 118.499 39.0532C118.132 38.6857 117.861 38.2409 117.648 37.7381C117.455 37.2159 117.358 36.655 117.358 36.0554C117.358 35.4365 117.455 34.8757 117.648 34.3728C117.861 33.87 118.151 33.4251 118.519 33.0577C118.886 32.6902 119.311 32.4001 119.795 32.2067C120.298 32.0133 120.82 31.9166 121.42 31.9166C122.019 31.9166 122.541 32.0133 123.044 32.2067C123.547 32.4001 123.973 32.6902 124.321 33.0577C124.688 33.4251 124.978 33.87 125.172 34.3728C125.384 34.8757 125.481 35.4365 125.481 36.0554C125.5 36.6743 125.384 37.2352 125.172 37.7381Z"
          fill="white"
        />
        <path
          d="M131.902 34.8368C131.863 35.9586 131.786 36.9063 131.67 37.6799C131.554 38.4535 131.341 39.0531 131.051 39.4786C130.761 39.8847 130.374 40.0975 129.871 40.0975C129.678 40.0975 129.504 40.0781 129.33 40.0201L129.136 42.573C129.427 42.6311 129.678 42.6891 129.91 42.7278C130.161 42.7664 130.394 42.7858 130.606 42.7858C131.361 42.7858 131.979 42.6117 132.482 42.2442C132.985 41.8768 133.372 41.3546 133.643 40.6583C133.914 39.9621 134.146 39.1304 134.281 38.1247C134.416 37.1384 134.513 35.9973 134.532 34.7208L134.629 32.0131H139.097V42.5537H142.075V29.5569H132.096L131.902 34.8368Z"
          fill="white"
        />
        <path
          d="M148.322 37.1384H154.356V34.7982H148.322V31.9744H155.149V29.5569H145.324V42.5537H155.381V40.1361H148.322V37.1384Z"
          fill="white"
        />
        <path
          d="M168.069 36.3648C167.721 36.1134 167.315 35.92 166.831 35.7846C167.063 35.6685 167.276 35.5525 167.469 35.3978C168.262 34.7982 168.649 33.9666 168.649 32.9028C168.649 31.8198 168.224 30.9881 167.392 30.4079C166.56 29.8277 165.381 29.5376 163.872 29.5376H157.818V42.5537H164.22C165.864 42.5537 167.121 42.225 167.972 41.5867C168.842 40.9291 169.268 40.0395 169.268 38.8984C169.268 37.796 168.881 36.945 168.069 36.3648ZM160.816 31.7811H163.621C164.278 31.7811 164.781 31.9165 165.11 32.1872C165.439 32.4387 165.613 32.8448 165.613 33.3477C165.613 33.8699 165.439 34.2567 165.11 34.5274C164.781 34.7789 164.278 34.9143 163.621 34.9143H160.816V31.7811ZM165.69 39.9234C165.323 40.1942 164.8 40.3296 164.104 40.3296H160.797V37.0417H164.104C164.8 37.0417 165.323 37.1771 165.69 37.4479C166.057 37.6993 166.232 38.1054 166.232 38.647C166.232 39.2272 166.057 39.6527 165.69 39.9234Z"
          fill="white"
        />
        <path d="M66.5313 41.2H0.193405V43.134H66.5313V41.2Z" fill="white" />
        <path
          d="M53.7085 18.9971L49.5503 14.8389C49.4536 14.7422 49.2989 14.7422 49.2022 14.8389L45.044 18.9971C44.9473 19.0938 44.9473 19.2485 45.044 19.3452L49.2022 23.5034C49.2989 23.6001 49.4536 23.6001 49.5503 23.5034L53.7085 19.3452C53.8052 19.2485 53.8052 19.0938 53.7085 18.9971Z"
          fill="white"
        />
        <path
          d="M20.8684 18.9971L16.7102 14.8389C16.6135 14.7422 16.4588 14.7422 16.3621 14.8389L12.2039 18.9971C12.1071 19.0938 12.1071 19.2485 12.2039 19.3452L16.3621 23.5034C16.4588 23.6001 16.6135 23.6001 16.7102 23.5034L20.8684 19.3452C20.9651 19.2485 20.9651 19.0938 20.8684 18.9971Z"
          fill="white"
        />
      </svg>
    </div>
    <div class="footer__info">
      <p>ФОП Бринза О.Ю.</p>
      <p class="key">Отримувач</p>
      <p class="value">ФОП Бринза Олена Юріївна</p>
      <p class="key">ІПН/ЄДРПОУ</p>
      <p class="value">3148417685</p>
      <p class="key">Адреса</p>
      <p class="value">м. Запоріжжя, вул.Артилеристів,29</p>
      <p class="key">Телефон</p>
      <p class="value">097-692-54-02</p>
    </div>

    <div class="footer__policy">
      <router-link :to="{ name: 'policy' }" target="_blank">
        Публічний договір</router-link
      >
    </div>
  </footer>
</template>
